/*
 * Custom fonts
 * Uncomment below if using custom fonts
 */
import "./static/fonts/fonts.css";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import firebase from "gatsby-plugin-firebase";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}
